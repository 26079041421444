import React from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { HubspotTracker } from '@/app/components/hubspotTracker/hubspotTracker';
import '@/app/globals.css';
import RootLayout from '@/app/layouts/landing-page';
import ErrorBoundary from '@/app/components/errorComponents/errorBoundary';
import Support from '@/app/components/support/support';
import { SupportProvider, useSupport } from '@/app/context/support-context';
import PaymentRedirect from '@/app/components/payment-handler/payment-redirect';
import {
  ToastAlertProvider,
  useToastAlert,
} from '@/app/context/toast-alert-context';
import ToastAlert from '@/app/components/shared/toast-alert/toast-alert';
import dynamic from 'next/dynamic';
import { WebSocketProvider } from '@/app/context/websocket-context';
import ProcessSubscriber from '@/app/components/notification/processSubscriber';
import { NotificationProvider } from '@/app/context/notification-context';

const GAConsentManager = dynamic(() => import('@/app/components/ga-consent-manager/ga-consent-manager'), {
  ssr: false,
});

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  
  return (
    <>
      <Head>
        {/* <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap" rel="stylesheet"/>*/}
        {/* To override this title and meta put the Head tag in your page */}
        <title>GDI</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <HubspotTracker />
      </Head>
      <UserProvider>
        <SupportProvider>
          <ToastAlertProvider>
            <WebSocketProvider>
            <NotificationProvider>
              <RootLayout>
                <Component {...pageProps} />
                <SupportComponent />
                <ToastAlertComponent />
                <ProcessSubscriber />
                <PaymentRedirect />
              </RootLayout>
              </NotificationProvider>
            </WebSocketProvider>
          </ToastAlertProvider>
        </SupportProvider>
      </UserProvider>
      <GAConsentManager/>
    </>
  );
};

const SupportComponent: React.FC = () => {
  const { isOpen, closeSupport } = useSupport();

  return <Support isOpen={isOpen} closeModal={closeSupport} />;
};

const ToastAlertComponent: React.FC = () => {
  const { isOpen, message, severity, closeToastAlert } = useToastAlert();

  return (
    <ToastAlert
      isOpen={isOpen}
      message={message}
      severity={severity}
      closeToastAlert={closeToastAlert}
    />
  );
};

export default MyApp;
