import React, { createContext, useContext, useState } from 'react';
// interface UserProfileContextProps {
//   userProfile: any;
//   isUserProfileLoading: boolean;
//   error: string | null;
// }


export const NotificationContext = createContext<any>( undefined);

export const NotificationProvider = ({ children }: any) => {
    const [notificationData, setNotificationData] = useState('');
  
    return (
      <NotificationContext.Provider value={{ notificationData, setNotificationData }}>
        {children}
      </NotificationContext.Provider>
    );
  };

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotificationContext must be used within a useNotificationProvider');
  }
  return context;
};
