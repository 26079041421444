import { createContext, useContext, useMemo, useState } from 'react';
/**
 * Severity is the type of alert severity
 * @typedef {'info' | 'error' | 'warning' | 'success'} Severity
 */
type Severity = 'info' | 'error' | 'warning' | 'success';
/**
 * AlertContextProps is the shape of the context value
 * @property {boolean} isOpen - Whether the alert is open
 * @property {string} message - The message to display in the alert
 * @property {Severity} severity - The severity of the alert
 * @property {Function} openToastAlert - Function to open the alert
 * @property {Function} closeToastAlert - Function to close the alert
 * @typedef {object} AlertContextProps
 */
interface ToastAlertContextProps {
  isOpen: boolean;
  message: string;
  severity: 'info' | 'error' | 'warning' | 'success' | undefined;
  openToastAlert: (message: string, severity: Severity) => void;
  closeToastAlert: () => void;
}

const ToastAlertContext = createContext<ToastAlertContextProps | undefined>(undefined);
/**
 * ToastAlertProvider provides the alert context to its children.
 * @param {React.ReactNode} children
 * @returns {JSX.Element} The provider component
 */
export const ToastAlertProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<Severity>();

  const openToastAlert = (message: string, severity: Severity) => {
    setIsOpen(true);
    setSeverity(severity);
    setMessage(message);
  };

  const closeToastAlert = () => {
    setIsOpen(false);
    // Delay clearing the message until the exit animation completes
    // setTimeout(() => {
    //   setMessage('');
    // }, 300); // Adjust the delay (in ms) as needed
  };
  
  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({ isOpen, message, severity, openToastAlert, closeToastAlert }),
    [isOpen, message, severity],
  );
  return (
    <ToastAlertContext.Provider value={contextValue}>
      {children}
    </ToastAlertContext.Provider>
  );
};
/**
 * Custom hook to access the alert context
 * @returns {AlertContextProps} The alert context value
 * @throws {Error} - If the hook is used outside of a ToastAlertProvider
 */
export const useToastAlert = () => {
    const context = useContext(ToastAlertContext);
    if (!context) {
        throw new Error('useToastAlert must be used within a ToastAlertProvider');
    }
    return context;
};
