import { createContext, useContext, useState, useMemo, useEffect, useRef } from "react";
import { io, Socket } from "socket.io-client";
import axios from "axios";
import { useToastAlert } from "./toast-alert-context";
import { Notification } from "src/app/types/notification"
import Logger from '../utils/logger';
import { useStorageSync } from "../hooks/useStorageSync";

/**
 * WebSocketContextProps is the shape of the context value
 * @property {(processId: string) => void} subscribeToProcess - Function to subscribe to a process
 * @property {(processId: string) => void} unsubscribeFromProcess - Function to unsubscribe from a process
 * @property {Socket | null} socket - The socket instance
 * @typedef {object} WebSocketContextProps
 */
interface WebSocketContextProps {
  subscribeToProcess: (processId: string) => void;
  unsubscribeFromProcess: (processId: string) => void;
  socket: Socket | null;
  newNotificationReceived: () => void;
}

const WebSocketContext = createContext<WebSocketContextProps | undefined>(
  undefined
);


/**
 * WebSocketProvider component that provides the WebSocket context to its children.
 * @param {React.ReactNode} children
 * @returns {JSX.Element} The provider component
 */
export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [notificationCount, setNotificationCount] = useStorageSync("user-notification-count", 0);
  const [inprogressJobs, setInprogressJobs] = useStorageSync("user-jobs-inprogress", [])
  const socketRef = useRef<Socket | null>(null);
  const { openToastAlert } = useToastAlert();
  
  const [notificationTrigger, setNotificationTrigger] = useState(0);
  const newNotificationReceived = () => {
    setNotificationTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    const initializeSocket = async () => {
      try {
        if(!(await isUserLoggedIn()))
          return;

        const response = await axios.get("/api/auth/token"); 
        const authToken = response.data;

        const newSocket = io(`${process.env.BACKEND_URL}/backend/process-ws`, {
          transports: ["websocket"],
          query: {
            t: authToken, 
          },
        });

        newSocket.on("connect", () => {
          //console.log("WebSocketProvider connected to WebSocket");
        });

        newSocket.on("disconnect", () => {
          //console.log("WebSocketProvider disconnected");
        });

        newSocket.on("processUpdate", (data: Notification) => {
          if(notificationCount !== null){
            setNotificationCount(notificationCount+1)
          }
          else{
            setNotificationCount(1)
          }
          if(data.processExternalId){
            setInprogressJobs(inprogressJobs?.filter((item: any) => item?.processExternalId !== data.processExternalId));          }
          unsubscribeFromProcess(data.processExternalId);
          openToastAlert(data.message, (data.statusCode == 200)? 'success': 'error');
          
          if(data.statusCode == 200){
            newNotificationReceived();
          }
        });

        socketRef.current = newSocket;

      } catch (error) {
        //console.error("Failed to fetch auth token or connect WebSocket:", error);
        Logger.error(`Failed to fetch auth token or connect WebSocket. ${JSON.stringify(error)})`, "client");
      }
    };

    const initialize = async () => {
      initializeSocket();
    };

    initialize();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);
  
  const isUserLoggedIn = async () => {
    let userLogged = undefined;
    try{
      userLogged = await axios.get("/api/auth/me");
    } catch(error){
      return false;
    }        
    return (userLogged != undefined);
  }

  const subscribeToProcess = (processId: string) => {
    if (socketRef.current) {
      socketRef.current.emit("subscribeToProcess", { processExternalId: processId });
      //console.log(`Subscribed to process: ${processId}`);
    }
  };

  const unsubscribeFromProcess = (processId: string) => {
    if (socketRef.current) {
      socketRef.current.emit("unsubscribeFromProcess", { processExternalId: processId });
      //console.log(`Unsubscribed from process: ${processId}`);
    }
  };

  // Memorize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      subscribeToProcess,
      unsubscribeFromProcess,
      socket: socketRef.current,
      newNotificationReceived
    }),
    [newNotificationReceived]
  );

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};

/**
 * Custom hook to access the WebSocket context
 * @throws {Error} - If the hook is used outside of a WebSocketProvider
 * @returns {WebSocketContextProps} The WebSocket context value
 */
export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};